import { SvgIconStyle } from '@onedesign/ui';
import Alert from '/static/svg/ic_alert.svg';
import Point from '/static/svg/ic_point.svg';
import Person from '/static/svg/ic_person.svg';
import Calendar from '/static/svg/ic_calendar.svg';
import CalendarDefault from '/static/svg/ic_calendar_default.svg';
import CalendarDaySolid from '/static/svg/ic_calendar_day_solid.svg';
import ChatRoom from '/static/svg/ic_chatroom.svg';
import UserConfig from '/static/svg/ic_user_cog_solid.svg';
import Trash from '/static/svg/trash_alt_solid.svg';
import EyeOn from '/static/svg/ic_eye_base_on.svg';
import EyeOff from '/static/svg/ic_eye_base_off.svg';
import Introduction from '/static/svg/ic_introduction.svg';
import Search from '/static/svg/ic_search.svg';
import View from '/static/svg/ic_eye.svg';
import Edit from '/static/svg/ic_edit.svg';
import Plus from '/static/svg/plus_outline.svg';
import Close from '/static/svg/ic_close.svg';
import Settings from '/static/svg/ic_settings.svg';
import CaretDown from '/static/svg/ic_caret_down.svg';
import ChevronDown from '/static/svg/ic_chevron_down.svg';
import ChevronLeft from '/static/svg/ic_chevron_left.svg';
import ChevronRight from '/static/svg/ic_chevron_right.svg';
import ChevronDoubleDown from '/static/svg/ic_chevron_double_down.svg';
import ChevronDoubleMinus from '/static/svg/ic_chevron_double_minus.svg';
import Link from '/static/svg/ic_attach_file.svg';
import SignIn from '/static/svg/sign_in_alt_solid.svg';
import Error from '/static/svg/ic_error.svg';
import Minus from '/static/svg/ic_minus.svg';
import Send from '/static/svg/ic_send.svg';
import Copy from '/static/svg/copy_outline.svg';
import ChartPie from '/static/svg/chart_pie_solid.svg';
import Expand from '/static/svg/expand_alt_solid.svg';
import Customer from '/static/svg/users_solid.svg';
import Upload from '/static/svg/file_upload_solid.svg';
import Check from '/static/svg/ic_check.svg';
import Badge from '/static/svg/id_badge_solid.svg';
import Star from '/static/svg/ic_star.svg';
import Remove from '/static/svg/ic_remove.svg';
import Refresh from '/static/svg/ic_refresh.svg';
import UserGroup from '/static/svg/ic_user_group.svg';
import FilterBtn from '/static/svg/ic_adjust.svg';
import More from '/static/svg/ic_more.svg';
import MoneyBill from '/static/svg/money_bill-wave_solid.svg';
import EditOutline from '/static/svg/edit_outline.svg';
import CheckMark from '/static/svg/ic_checkmark.svg';
import Download from '/static/svg/ic_download.svg';
import Launch from '/static/svg/ic_launch.svg';
import ThumbsUp from '/static/svg/thumbs_up_outline.svg';
import File from '/static/svg/ic_file.svg';
import Language from '/static/svg/ic_language.svg';
import ExpandMore from '/static/svg/ic_expand_more.svg';
import Pastafarianism from '/static/svg/pastafarianism_solid.svg';
import Info from '/static/svg/ic_info.svg';
import CommentDots from '/static/svg/comment_dots_outline.svg';
import ChalkboardTeacher from '/static/svg/chalkboard_teacher_solid.svg';
import Videocam from '/static/svg/videocam_solid.svg';
import User from '/static/svg/user_solid.svg';
import Bag from '/static/svg/bag_solid.svg';
import DateTime from '/static/svg/ic_datetime.svg';
import School from '/static/svg/school_solid.svg';
import StickyNote from '/static/svg/sticky_note.svg';
import Tag from '/static/svg/ic_tag_solid.svg';
import Order from '/static/svg/ic_order.svg';
import ArrowDown from '/static/svg/ic_arrow_down.svg';
import PaperClip from '/static/svg/ic_paper_clip.svg';
import Dialog from '/static/svg/ic_dialog_solid.svg';
import ArrowThinUp from '/static/svg/ic_arrow_thin_up.svg';
import ArrowDownUp from '/static/svg/ic_arrow_thin_down.svg';
import Advertisement from '/static/svg/ic_advertisement.svg';
import Arrow from '/static/svg/arrow.svg';
import CustomerTicket from '/static/svg/ic_customer_ticket.svg';
import Add from '/static/svg/clipboard_add_solid.svg';
import Receipt from '/static/svg/ic_receipt_solid.svg';
import OneAnswer from '/static/svg/one_answer.svg';
import TicketHistory from '/static/svg/ic_ticket_history.svg';
import TicketChannel from '/static/svg/ic_ticket_channel.svg';
import TicketNotify from '/static/svg/ic_ticket_notify.svg';
import Chat from '/static/svg/chat.svg';

import { SxProps } from '@mui/material';

const SVG = {
  Alert,
  Point,
  Person,
  Calendar,
  CalendarDefault,
  CalendarDaySolid,
  ChatRoom,
  UserConfig,
  Trash,
  EyeOn,
  EyeOff,
  Introduction,
  Search,
  View,
  Edit,
  Plus,
  Close,
  Chat,
  Settings,
  CaretDown,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronDoubleDown,
  ChevronDoubleMinus,
  Link,
  SignIn,
  Error,
  Minus,
  Send,
  Copy,
  ChartPie,
  Expand,
  Customer,
  Upload,
  Check,
  Badge,
  Star,
  Remove,
  Refresh,
  UserGroup,
  FilterBtn,
  More,
  MoneyBill,
  EditOutline,
  CheckMark,
  Download,
  Launch,
  ThumbsUp,
  File,
  Language,
  ExpandMore,
  Pastafarianism,
  Info,
  CommentDots,
  ChalkboardTeacher,
  Videocam,
  User,
  Bag,
  School,
  StickyNote,
  Tag,
  DateTime,
  Order,
  ArrowDown,
  PaperClip,
  Dialog,
  ArrowThinUp,
  ArrowDownUp,
  Advertisement,
  Arrow,
  CustomerTicket,
  Add,
  Receipt,
  OneAnswer,
  TicketHistory,
  TicketChannel,
  TicketNotify,
};

export enum SvgName {
  Alert = 'Alert',
  Point = 'Point',
  Person = 'Person',
  Calendar = 'Calendar',
  CalendarDefault = 'CalendarDefault',
  CalendarDaySolid = 'CalendarDaySolid',
  ChatRoom = 'ChatRoom',
  UserConfig = 'UserConfig',
  Trash = 'Trash',
  EyeOn = 'EyeOn',
  EyeOff = 'EyeOff',
  Introduction = 'Introduction',
  Search = 'Search',
  View = 'View',
  Edit = 'Edit',
  Plus = 'Plus',
  Close = 'Close',
  Settings = 'Settings',
  CaretDown = 'CaretDown',
  ChevronDown = 'ChevronDown',
  ChevronLeft = 'ChevronLeft',
  ChevronRight = 'ChevronRight',
  ChevronDoubleDown = 'ChevronDoubleDown',
  ChevronDoubleMinus = 'ChevronDoubleMinus',
  Link = 'Link',
  SignIn = 'SignIn',
  Error = 'Error',
  Minus = 'Minus',
  Send = 'Send',

  Chat = 'Chat',
  Copy = 'Copy',
  ChartPie = 'ChartPie',
  Expand = 'Expand',
  Customer = 'Customer',
  Upload = 'Upload',
  Check = 'Check',
  Badge = 'Badge',
  Star = 'Star',
  Remove = 'Remove',
  Refresh = 'Refresh',
  UserGroup = 'UserGroup',
  FilterBtn = 'FilterBtn',
  More = 'More',
  MoneyBill = 'MoneyBill',
  EditOutline = 'EditOutline',
  CheckMark = 'CheckMark',
  Download = 'Download',
  Launch = 'Launch',
  ThumbsUp = 'ThumbsUp',
  File = 'File',
  Language = 'Language',
  ExpandMore = 'ExpandMore',
  Pastafarianism = 'Pastafarianism',
  Info = 'Info',
  CommentDots = 'CommentDots',
  ChalkboardTeacher = 'ChalkboardTeacher',
  Videocam = 'Videocam',
  User = 'User',
  Bag = 'Bag',
  School = 'School',
  StickyNote = 'StickyNote',
  Tag = 'Tag',
  DateTime = 'DateTime',
  Order = 'Order',
  ArrowDown = 'ArrowDown',
  PaperClip = 'PaperClip',
  Dialog = 'Dialog',
  ArrowThinUp = 'ArrowThinUp',
  ArrowDownUp = 'ArrowDownUp',
  Advertisement = 'Advertisement',
  Arrow = 'Arrow',
  CustomerTicket = 'CustomerTicket',
  Add = 'Add',
  Receipt = 'Receipt',
  OneAnswer = 'OneAnswer',
  TicketHistory = 'TicketHistory',
  TicketChannel = 'TicketChannel',
  TicketNotify = 'TicketNotify',
}

type Props = {
  name: SvgName;
  sx?: SxProps;
} & React.HTMLAttributes<HTMLDivElement>;

export const Icon = ({
  name,
  sx = { width: '24px', height: '24px' },
  ...rest
}: Props) => {
  return <SvgIconStyle src={SVG[name]} sx={sx} {...rest} />;
};
