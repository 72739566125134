import { NotificationTypes } from 'constants/notification.constants';
import { useState } from 'react';
import { alpha } from '@mui/material/styles';
import { useSendbirdService } from 'services/SendbirdService';
import { MenuPopover, IconButtonAnimate, Mui } from '@onedesign/ui';
import { Icon, SvgName } from 'components/Icon';
import { navigateTo } from 'utils/common';
import { EApiStatus } from '@type/common';
import { useNavigate } from 'react-router-dom';
import { useAuthService } from 'services/AuthService';
import { usePointsService } from 'services/PointsService';
import queryString from 'query-string';
import { useSnackbar } from 'notistack';
import path, { Auth } from 'routes/path';
import cookies, { KeyCookie } from 'plugins/cookie/cookie';
import axios from 'plugins/api/axios';
import { IStaff } from '@type/staff.type';
import { useRemoteConfigService } from 'services/RemoteConfigService';
import { useMarkAsReadNotification } from 'hooks/query/notificationCenter';
import EditNicknameModal from './EditNicknameModal';
import SendbirdChannelNotify from './components/sendbirdchannelnotify/SendbirdChannelNotify';
import SendbirdTicketChannel from './components/sendbirdticketchannel/SendbirdTicketChannel';
import TicketNotify from './components/ticketnotify/TicketNotify';

const { backstage } = axios;

const { Box, Divider, Typography, MenuItem, Avatar, useTheme, Badge } = Mui; // Stack

// ----------------------------------------------------------------------

export type NormalNotification = {
  type:
    | NotificationTypes.TICKETS_NEW
    | NotificationTypes.TICKETS_ASSIGN
    | NotificationTypes.TICKETS_TRANSFER_MYSELF;
  id: string;
  isRead: boolean;
};

export type TicketTransferNotification = {
  type: NotificationTypes.TICKETS_TRANSFER_DEPARTMENT;
  departmentId: string;
  id: string;
  isRead: boolean;
};

export type LeaveOrderNotification = {
  type: NotificationTypes.LEAVE_ORDER_NEW;
  id: string;
  relatedObjectId: string;
  isRead: boolean;
};

type TProps = {
  loginUser: any;
  setLoginUser: (arg: any) => void;
};

const AccountPopover = ({ loginUser, setLoginUser }: TProps) => {
  const permissions = loginUser?.permissions || [];
  const includeAuth = (auth: string[]) =>
    auth.some((item: any) => permissions.includes(item));
  const { parameter } = useRemoteConfigService();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const [, { emptyAuthData }] = useAuthService();
  const [, { emptyPointsData }] = usePointsService();
  const navigate = useNavigate();
  const { name, staffNumber, nickname, id } = loginUser;
  const [open, setOpen] = useState<HTMLElement | null>(null);
  // 編輯個人暱稱
  const [openEditNicknameModal, setOpenEditNicknameModal] = useState(false);
  const [editNickname, setEditNickname] = useState(nickname ?? '');
  const [loading, setLoading] = useState(false);
  const [openTicketNotify, setOpenTicketNotify] = useState(false);
  const [openChannelNotify, setOpenChannelNotify] = useState(false);
  const [openTicketChannel, setOpenTicketChannel] = useState(false);
  const {
    customerChannelUnreadCount,
    teacherChannelUnreadCount,
    ticketChannelUnreadCount,
    notificationList,
    notifyCount,
    isNotificationLoading,
    refetchNotificationList,
  } = useSendbirdService();
  const patchNotifications = useMarkAsReadNotification();
  // 有教師聊天權限or家長聊天權限才顯示
  const hasChannelPermission = includeAuth([
    Auth.TEACHER_TALK,
    Auth.PARENT_MESSAGE_EDIT,
  ]);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    const oneClassMMSToken = cookies.get(
      KeyCookie.oneClassMMSClient,
    )?.accessToken;
    const oneClassToken = cookies.get(KeyCookie.oneClassClient);
    if (oneClassToken?.jwt?.includes(oneClassMMSToken)) {
      cookies.remove(KeyCookie.oneClassClient);
    }
    cookies.remove(KeyCookie.oneClassMMSClient);
    cookies.remove(KeyCookie.examRole);
    navigate(path.LOGIN);
    emptyAuthData();
    emptyPointsData();
    setLoginUser(undefined);
  };

  // 開啟編輯暱稱彈窗
  const handleOpenEditNicknameModal = () => {
    setOpenEditNicknameModal(true);
  };

  // 編輯暱稱確認
  const handleEditNickname = async () => {
    const payload = {
      nickname: editNickname,
    };

    setLoading(true);
    const { status, data } = await backstage.updateStaff(payload, id);

    if (status === EApiStatus.SUCCESS) {
      enqueueSnackbar('編輯暱稱成功', { variant: 'success' });
      setLoginUser((prev: IStaff) => ({
        ...prev,
        nickname: data.nickname,
      }));
    } else {
      enqueueSnackbar('編輯暱稱失敗', { variant: 'error' });
    }

    setOpenEditNicknameModal(false);
    setLoading(false);
  };

  const handleOpenTicketChannel = () => {
    setOpenTicketChannel(prev => !prev);
    setOpenTicketNotify(false);
    setOpenChannelNotify(false);
  };

  const handleOpenChannel = () => {
    setOpenChannelNotify(prev => !prev);
    setOpenTicketNotify(false);
    setOpenTicketChannel(false);
  };

  const handleOpenTicketNotify = () => {
    setOpenTicketNotify(prev => !prev);
    setOpenChannelNotify(false);
    setOpenTicketChannel(false);
  };

  const handleOpenNotification = async (
    data:
      | NormalNotification
      | TicketTransferNotification
      | LeaveOrderNotification,
  ) => {
    let queryOptions: Record<string, string | number> = {};
    let queryPath = '';

    switch (data.type) {
      case NotificationTypes.TICKETS_NEW:
        queryPath = path.CUSTOMER_TICKET_INSTANT_CASE;
        break;
      case NotificationTypes.TICKETS_TRANSFER_MYSELF:
        queryOptions = { tab: 'processing', tutorRange: 'myself' };
        queryPath = queryString.stringifyUrl({
          url: path.CUSTOMER_TICKET_INSTANT_CASE,
          query: queryOptions,
        });
        break;
      case NotificationTypes.TICKETS_TRANSFER_DEPARTMENT:
        queryOptions = { tab: 'processing', departmentIds: data.departmentId };
        queryPath = queryString.stringifyUrl({
          url: path.CUSTOMER_TICKET_INSTANT_CASE,
          query: queryOptions,
        });
        break;
      case NotificationTypes.LEAVE_ORDER_NEW:
        queryPath = `${path.AUDITION_LEAVE_READ}/${data.relatedObjectId}`;
        break;
      default:
        queryOptions = { tab: 'processing' };
        queryPath = queryString.stringifyUrl({
          url: path.CUSTOMER_TICKET_INSTANT_CASE,
          query: queryOptions,
        });
    }

    navigateTo(queryPath);

    if (data.isRead) return;

    const { err } = await patchNotifications.mutateAsync(data.id);
    if (err !== 0) {
      enqueueSnackbar('改變已讀失敗', { variant: 'error' });
    }
    refetchNotificationList();
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignContent: 'center',
          position: 'relative',
        }}
      >
        {parameter.ONECLUB_7285 && (
          <>
            <Box
              onClick={handleOpenTicketNotify}
              sx={{
                mr: '20px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Badge badgeContent={notifyCount} color="error">
                <Icon
                  name={SvgName.TicketNotify}
                  sx={{
                    background: theme.palette.grey[600],
                    cursor: 'pointer',
                    width: '27px',
                    height: '27px',
                  }}
                />
              </Badge>

              {openTicketNotify && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: '100%',
                    left: '-160%',
                  }}
                >
                  <TicketNotify
                    notificationList={notificationList}
                    isLoading={isNotificationLoading}
                    handleOpenNotification={handleOpenNotification}
                  />
                </Box>
              )}
            </Box>
            {hasChannelPermission && (
              <>
                <Box
                  onClick={handleOpenChannel}
                  sx={{
                    mr: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Badge
                    badgeContent={
                      customerChannelUnreadCount + teacherChannelUnreadCount
                    }
                    color="error"
                  >
                    <Icon
                      name={SvgName.Dialog}
                      sx={{
                        background: theme.palette.grey[600],
                        cursor: 'pointer',
                        width: '27px',
                        height: '27px',
                      }}
                    />
                  </Badge>
                </Box>
                {openChannelNotify && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '30%',
                      left: '-150%',
                    }}
                  >
                    <SendbirdChannelNotify />
                  </Box>
                )}
              </>
            )}
            <Box
              sx={{
                mr: '22px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={handleOpenTicketChannel}
            >
              <Badge badgeContent={ticketChannelUnreadCount} color="error">
                <Icon
                  name={SvgName.TicketChannel}
                  sx={{
                    background: theme.palette.grey[600],
                    cursor: 'pointer',
                    width: '27px',
                    height: '27px',
                  }}
                />
              </Badge>
              {openTicketChannel && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: '30%',
                    left: '-125%',
                  }}
                >
                  <SendbirdTicketChannel />
                </Box>
              )}
            </Box>
          </>
        )}
        <IconButtonAnimate
          onClick={handleOpen}
          sx={{
            p: 0,
            ...(open && {
              '&:before': {
                zIndex: 1,
                content: "''",
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                position: 'absolute',
                bgcolor: theme => alpha(theme.palette.grey[900], 0.8),
              },
            }),
          }}
          css={{}}
        >
          <Avatar src="" alt="oneClub" />
        </IconButtonAnimate>
      </Box>
      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {staffNumber}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography
              variant="body2"
              sx={{ color: 'text.secondary', mt: '2px' }}
              noWrap
            >
              {nickname}
            </Typography>
            <Box onClick={handleOpenEditNicknameModal}>
              <Icon
                name={SvgName.Edit}
                sx={{ color: theme.palette.primary.main, cursor: 'pointer' }}
              />
            </Box>
          </Box>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />
        <Divider sx={{ borderStyle: 'dashed' }} />
        <MenuItem sx={{ m: 1 }} onClick={handleLogout}>
          Logout
        </MenuItem>
      </MenuPopover>
      {openEditNicknameModal && (
        <EditNicknameModal
          open={openEditNicknameModal}
          onClose={() => setOpenEditNicknameModal(false)}
          onDisConfirm={() => setOpenEditNicknameModal(false)}
          handleEditNickname={handleEditNickname}
          editNickname={editNickname}
          setEditNickname={setEditNickname}
          loading={loading}
        />
      )}
    </>
  );
};

export default AccountPopover;
