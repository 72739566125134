import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useLoadingService } from 'services/LoadingService';
import cookies, { KeyCookie } from 'plugins/cookie/cookie';
import path from 'routes/path';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { routerTo } from 'utils/helpr/permission';
import axios from 'plugins/api/axios';
const { backstage, sendbird } = axios;

export interface IUserData {
  IMAccessToken: string;
  name: string;
  permissions: string[];
  createdAt: number;
  staffNumber: string;
  departments: string[];
  isSuspended: boolean;
  id: string;
  departmentName: {
    _id: string;
    name: string;
  }[];
  channelTypes: {
    id: string;
    name: string;
  }[];
}
interface IUseLogin {
  isLoading: boolean;
  loginUser: IUserData | null;
  setLoginUser: Dispatch<SetStateAction<IUserData | null>>;
  checkAccessTokenExp: () => void;
  handleLoginPermission: () => Promise<void>;
}

const useLogin = (): IUseLogin => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { showLoading, hideLoading } = useLoadingService();
  const [isLoading, setIsLoading] = useState(true);
  const [loginUser, setLoginUser] = useState<IUserData | null>(null);
  const checkAccessTokenExp = () => {
    const accessToken = cookies.get(KeyCookie.oneClassMMSClient)?.accessToken;
    if (accessToken) {
      const { exp } = jwtDecode(accessToken) as JwtPayload;
      if (exp && exp * 1000 < Date.now()) {
        return false;
      }
      return true;
    }
    return false;
  };

  const removeCookies = () => {
    const oneClassMMSToken = cookies.get(
      KeyCookie.oneClassMMSClient,
    )?.accessToken;
    const oneclassToken = cookies.get(KeyCookie.oneClassClient);
    if (oneclassToken?.jwt?.includes(oneClassMMSToken)) {
      cookies.remove(KeyCookie.oneClassClient);
    }
    cookies.remove(KeyCookie.oneClassMMSClient);
    cookies.remove(KeyCookie.examRole);
  };

  const handleLoginPermission = async () => {
    if (checkAccessTokenExp()) {
      showLoading();
      const response = await backstage.getMe();

      hideLoading();
      if (response.status === 'success') {
        const currentData = response.data;
        const IMUser = await sendbird.getIMUser({
          oneClubId: currentData.staffNumber,
        });
        setLoginUser({
          ...currentData,
          IMAccessToken: IMUser.data?.accessToken,
        });
        setIsLoading(false);
        if (Object.values(path).includes(pathname)) {
          if (pathname === path.LOGIN || pathname === path.REGISTER) {
            routerTo(currentData.permissions, navigate);
            return;
          }
          navigate(pathname + search);
        }
      } else {
        // eslint-disable-next-line no-console
        console.log('getMe response:', response);
        removeCookies();
        setIsLoading(false);
        navigate(path.LOGIN);
      }
    } else {
      removeCookies();
      setIsLoading(false);
      navigate(path.LOGIN);
    }
  };

  return {
    isLoading,
    loginUser,
    setLoginUser,
    checkAccessTokenExp,
    handleLoginPermission,
  };
};

function createService<T>(
  func: (...args: any[]) => T,
  initialValue: T | undefined = undefined,
) {
  return createContext(initialValue as T);
}
export const LoginService = createService(useLogin);
export const LoginProvider: React.FC = ({ children }) => {
  return (
    <LoginService.Provider value={useLogin()}>{children}</LoginService.Provider>
  );
};

export const useLoginService = () => useContext(LoginService);
