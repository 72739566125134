export enum SENDBIRD_CHANNEL_TYPE {
  TEACHER_CHAT_ONE_TO_ONE = 'teacher_chat_one_to_one',
}

export enum ETicketLogType {
  CREATE = 'create', // 建立案件
  EDIT = 'edit', // 編輯案件內容
  TRANSFER = 'transfer', // 轉交案件
  CLOSE = 'close', // 案件結束
}

export const ESendbirdTicketLogTypeLabel = {
  [ETicketLogType.CREATE]: '建立案件',
  [ETicketLogType.EDIT]: '編輯案件內容',
  [ETicketLogType.TRANSFER]: '轉交案件',
  [ETicketLogType.CLOSE]: '案件結束',
};

export enum EChannelTargetType {
  CUSTOMER = 'customer',
  TEACHER = 'teacher',
}
