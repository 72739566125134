import { useEffect } from 'react';
import { useLoginService } from 'services/LoginService';
import { Mui } from '@onedesign/ui';
import Router from 'routes/index';
import { SendbirdInitProvider } from 'services/SendbirdService';

const { Box } = Mui;

const Root = () => {
  const { isLoading, handleLoginPermission } = useLoginService();

  useEffect(() => {
    handleLoginPermission();
  }, []);
  return !isLoading ? (
    <SendbirdInitProvider>
      <Router />
    </SendbirdInitProvider>
  ) : (
    <Box />
  );
};

export default Root;
