import { ICourseTag } from './tag.type';

export enum EApiStatus {
  SUCCESS = 'success',
  FAILURE = 'failure',
}

export enum TeacherRecruitmentResponseStatus {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export interface IApiError {
  errorCode: string | number;
  message: string;
  exceptionMessage: string;
}

export type TApiResponse<T> = Promise<{
  status: EApiStatus;
  data: T;
  error?: IApiError;
}>;

export type TRCApiResponse<T> = Promise<{
  from: string;
  message: string;
  data?: T;
  error: number;
}>;

export type TSBApiResponse<T> = Promise<{
  from: string;
  msg?: string;
  data?: T;
  err: number;
}>;

export interface IPagnationParams {
  skip?: number; // 頁數Offset
  limit?: number; // 顯示筆數
  contactId?: string; // 聯絡人編號
  keyword?: string; // 搜尋條件(關鍵字)
  name?: string; // 搜尋條件(姓名)
  oneClubId?: string; // 搜尋條件(OneClubId)
  consultant?: string; // 搜尋條件(顧問)
  tutor?: string; // 搜尋條件(輔導老師)
  phone?: string; // 搜尋條件(電話號碼)
  email?: string; // 搜尋條件(信箱)
  paymentStatus?: string; // 搜尋條件(會員狀態))
  paidMember?: boolean; // 搜尋條件(是否為付費會員)
  contractStatus?: string; // 搜尋條件(合約狀態)
  courseTags?: ICourseTag[]; // 搜尋條件(課程標籤)
}

export type TOption = {
  label: string;
  value: string;
  id?: string;
};

export enum ESTATUS {
  ACTIVE = 'active',
  DISABLE = 'disable',
}

type JSONValue =
  | string
  | number
  | boolean
  | null
  | undefined
  | { [x: string]: JSONValue }
  | Array<JSONValue>;

export interface JSONObject {
  [x: string]: JSONValue;
}

export interface JSONArray extends Array<JSONValue> {}

export type Merge<M, N> = Omit<M, Extract<keyof M, keyof N>> & N;

export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export enum TIME {
  ONE_SECOND = 1000,
  ONE_MINUTES = 60 * ONE_SECOND,
  ONE_HOUR = 60 * ONE_MINUTES,
  ONE_DAY = 24 * ONE_HOUR,
  ONE_WEEK = 7 * ONE_DAY,
}

export type LabelColor =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'info'
  | 'success'
  | 'warning'
  | 'error';

export type TAddressObj = {
  county?: string;
  districts?: string;
  address?: string;
};

export type AddFieldUndefined<T> = { [K in keyof T]?: T[K] | undefined };

export type Option = {
  label: string;
  value: string | number;
};

export type TQueryParams = {
  limit?: number;
  skip?: number;
};

export interface ISearchField {
  [key: string]: string | number | boolean;
}

export interface ILog {
  id: string;
  createdAt: string | JSX.Element;
  content: string | JSX.Element;
  reason: string | JSX.Element;
  name: string | JSX.Element;
}
