// import LRU from 'lru-cache';
import { SALARY_FREEZE_STATUS } from 'constants/salary.constants';
import { AxiosInstance } from 'axios';
import {
  IGetAlIGetAllTeacherSalaryTotalResponse,
  IGetAllTeacherSalaryTotalParams,
  ISalaryDetailPayload,
  IGetDetailSalaryListParams,
  IGetDetailSalaryListResponse,
  IGetAllSalaryListResponse,
  IGetAllSalaryListParams,
  IGetTeacherSalaryDetailResponse,
  IPatchTeacherSalaryDetailPayload,
  IGetSalaryLogParams,
  IGetSalaryLogResponse,
} from '@type/financial/salary.type';
import { base64ToBlob } from 'utils/transfer';
import { EApiStatus, TApiResponse } from '@type/common';
import { ISalaryOverviewSearchParams } from '@type/financial/salaryOverview.type';

export default (axios: AxiosInstance, salary: string) => ({
  /**
   * 取得所有老師薪資列表
   */
  getAllSalaryList(
    data: IGetAllSalaryListParams,
  ): TApiResponse<IGetAllSalaryListResponse> {
    return axios.get(`${salary}/backend/salary/get-all-teacher-salary-list`, {
      params: data,
    });
  },
  /**
   * 取得老師薪資明細列表
   */
  getDetailSalaryList(
    data: IGetDetailSalaryListParams,
  ): TApiResponse<IGetDetailSalaryListResponse> {
    return axios.get(`${salary}/backend/salary/get-teacher-salary-list`, {
      params: data,
    });
  },
  /**
   * 取得老師薪資總計
   */
  getAllTeacherSalaryTotal(
    data: IGetAllTeacherSalaryTotalParams,
  ): TApiResponse<IGetAlIGetAllTeacherSalaryTotalResponse> {
    return axios.get(`${salary}/backend/salary/get-teacher-salary`, {
      params: data,
    });
  },
  /**
   * 取得教師薪資明細 by id
   */
  getTeacherSalaryDetail(
    id: string,
  ): TApiResponse<IGetTeacherSalaryDetailResponse> {
    return axios.get(
      `${salary}/backend/salary/get-teacher-salary-detail/${id}`,
    );
  },
  /**
   * 更新教師薪資明細
   */
  patchTeacherSalaryDetail(
    id: string,
    data: IPatchTeacherSalaryDetailPayload,
  ): TApiResponse<any> {
    return axios.patch(
      `${salary}/backend/salary/update-teacher-salary/${id}`,
      data,
    );
  },
  /**
   * 教師薪資 匯出excel
   */
  async exportSalary(payload: {
    startAt: string;
    endAt: string;
  }): Promise<any> {
    const { status, data } = (await axios.get(
      `${salary}/backend/salary/exportExcel`,
      {
        params: payload,
      },
    )) as { status: string; data: string };

    if (status === EApiStatus.SUCCESS) {
      const fileName = `教師薪資.xlsx`;
      const file = base64ToBlob(data);

      const url = window.URL.createObjectURL(new Blob([file]));
      const link = document.createElement('a');
      document.body.appendChild(link);
      link.href = url;
      link.download = fileName;
      link.click();
      document.body.removeChild(link);
      return true;
    } else {
      return false;
    }
  },
  /**
   * 匯出薪資 薪資明細
   */
  async exportSalaryDetail(
    payload: { startAt: string; endAt: string },
    id: string,
  ): Promise<any> {
    const { status, data } = (await axios.get(
      `${salary}/backend/salary/exportExcel/${id}`,
      {
        params: payload,
      },
    )) as { status: string; data: string };

    if (status === EApiStatus.SUCCESS) {
      const fileName = `薪資明細.xlsx`;
      const file = base64ToBlob(data);

      const url = window.URL.createObjectURL(new Blob([file]));
      const link = document.createElement('a');
      document.body.appendChild(link);
      link.href = url;
      link.download = fileName;
      link.click();
      document.body.removeChild(link);
      return true;
    } else {
      return false;
    }
  },
  /*
   * 取得薪資明細列表
   */
  getAllSalary(data: ISalaryOverviewSearchParams): any {
    return axios.get(`${salary}/backend/salary/allDetail`, { params: data });
  },
  /**
   * 編輯薪資
   */
  putEditSalary(
    data: ISalaryDetailPayload,
    salaryId: string,
  ): TApiResponse<any> {
    return axios.put(`${salary}/backend/salary/${salaryId}`, data);
  },
  /**
   * 取得資薪異動紀錄
   */
  getSalaryRecord(data: any): any {
    return axios.get(`${salary}/backend/salary-records`, { params: data });
  },

  /**
   * 教師薪資=>教師明細=>薪資異動紀錄
   */
  getSalaryLog(
    params: IGetSalaryLogParams,
  ): TApiResponse<IGetSalaryLogResponse> {
    return axios.get(`${salary}/backend/salary-log`, {
      params,
    });
  },
  /**
   * @description 取得老師薪資凍結狀態
   * @param teacherId 老師id(objectId，不是oneClubId)
   * @param data 目標年月，month 格式為兩位數字，如果是 1~9 要補 0
   * @returns { status: SALARY_FREEZE_STATUS; freezeSalaryId?: string; }
   */
  getSalaryFreezeStatus(
    teacherId: string,
    data: { month: string; year: string },
  ): TApiResponse<{
    status: SALARY_FREEZE_STATUS;
    freezeSalaryId?: string;
  }> {
    return axios.get(
      `${salary}/backend/salary/check-freeze-salary/${teacherId}`,
      { params: data },
    );
  },
  /**
   * @description 凍結/解凍老師薪資
   * @param teacherId 老師id(objectId，不是oneClubId)
   * @param data 目標年月、凍結/解凍
   * @returns { freezeSalaryId: string; }
   */
  updateTeacherSalaryFreezeStatus(
    teacherId: string,
    data: {
      year: string;
      month: string;
      isFreeze: boolean;
    },
  ): TApiResponse<{
    freezeSalaryId: string;
  }> {
    return axios.post(
      `${salary}/backend/salary/freeze-teacher-salary/${teacherId}`,
      data,
    );
  },
  /**
   * @description 取得凍結薪資紀錄
   * @param freezeSalaryId 凍結薪資id
   * @param data
   * @returns
   */
  getFreezeSalaryLog(
    freezeSalaryId: string,
    data: {
      skip: number;
      limit: number;
    },
  ): TApiResponse<{
    total: number;
    details: {
      action: 'update' | 'create';
      createdAt: string;
      operator: string;
      operatorName: string;
    }[];
  }> {
    return axios.get(
      `${salary}/backend/salary/get-freeze-salary/${freezeSalaryId}`,
      {
        params: data,
      },
    );
  },
});
