import { NotificationTypes } from 'constants/notification.constants';
import { Typography } from '@mui/material';
import { Mui } from '@onedesign/ui';
import { Loader } from 'components/Loader';
import { format } from 'date-fns';
import { alpha } from '@mui/material/styles';
import path from 'routes/path';
import {
  LeaveOrderNotification,
  NormalNotification,
  TicketTransferNotification,
} from '../../AccountPopover';

const { Box, useTheme, Divider } = Mui;

type TicketNotificationItem = {
  id: string;
  type:
    | NotificationTypes.TICKETS_ASSIGN
    | NotificationTypes.TICKETS_NEW
    | NotificationTypes.TICKETS_TRANSFER_DEPARTMENT
    | NotificationTypes.TICKETS_TRANSFER_MYSELF;
  data: {
    departmentId: string;
  };
  relatedObjectId: string;
  title: string;
  content: string;
  customerInfo: {
    name: string;
    oneClubId: string;
  };
  isRead: boolean;
  updatedAt: string;
};

type LeaveOrderNotificationItem = {
  id: string;
  type: NotificationTypes.LEAVE_ORDER_NEW;
  content: string;
  createdAt: string;
  customerInfo: {
    name: string;
    oneClubId: string;
  };
  isRead: boolean;
  relatedObjectId: string;
  title: string;
  updatedAt: string;
};

type TProps = {
  notificationList: (TicketNotificationItem | LeaveOrderNotificationItem)[];
  isLoading: boolean;
  handleOpenNotification: (
    data:
      | NormalNotification
      | TicketTransferNotification
      | LeaveOrderNotification,
  ) => void;
};

const TicketNotify = ({
  notificationList,
  isLoading,
  handleOpenNotification,
}: TProps) => {
  const theme = useTheme();

  const handleOpenNotificationCenter = () => {
    window.open(`${path.NOTIFICATION_CENTER}`, '_blank')?.focus();
  };

  return (
    <Box
      sx={{
        width: '320px',
        height: '533px',
        color: theme.palette.grey[800],
        backgroundColor: 'white',
        boxShadow: `-20px 20px 40px -4px  ${alpha(
          theme.palette.grey[500],
          0.24,
        )}`,
      }}
    >
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: '16px',
          lineHeight: '24px',
          py: '8px',
          px: '20px',
          height: '40px',
        }}
      >
        通知中心
      </Typography>
      <Divider />
      {isLoading && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Loader />
        </Box>
      )}
      {!isLoading && (
        <Box sx={{ height: '440px' }}>
          {notificationList.length > 0 &&
            notificationList.map(item => {
              return (
                <>
                  <Box
                    key={item.id}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      py: '12px',
                      px: '16px',
                      height: '88px',
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      handleOpenNotification({
                        type: item.type,
                        departmentId:
                          item.type === NotificationTypes.LEAVE_ORDER_NEW
                            ? ''
                            : item.data.departmentId,
                        id: item.id,
                        relatedObjectId: item.relatedObjectId,
                        isRead: item.isRead,
                      })
                    }
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mb: '8px',
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: '14px',
                          lineHeight: '16px',
                          color: theme.palette.grey[800],
                        }}
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: '12px',
                          color: theme.palette.grey[500],
                        }}
                      >
                        {format(new Date(item.updatedAt), 'hh:mm a')}
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: '12px',
                        color: theme.palette.grey[500],
                        mb: '4px',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                      }}
                    >
                      {item.content}
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alighItems: 'center',
                      }}
                    >
                      {item.type !== NotificationTypes.TICKETS_ASSIGN && (
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: '12px',
                            color: theme.palette.grey[500],
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            width: '280px',
                          }}
                        >
                          {item.customerInfo.name && item.customerInfo.name} (
                          {item.customerInfo.oneClubId})
                        </Typography>
                      )}
                      {!item.isRead && (
                        <Box
                          sx={{
                            height: '12px',
                            width: '12px',
                            backgroundColor: theme.palette.primary.main,
                            borderRadius: '50%',
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                  <Divider />
                </>
              );
            })}
        </Box>
      )}
      {!isLoading && (
        <Box
          sx={{
            color: theme.palette.primary.main,
            fontWeight: 700,
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mt: '14px',
          }}
          onClick={handleOpenNotificationCenter}
        >
          查看全部
        </Box>
      )}
    </Box>
  );
};

export default TicketNotify;
