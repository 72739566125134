import { TICKET_ROLE } from 'constants/customerTicket.constants';
import { AxiosInstance } from 'axios';
import { TSBApiResponse } from '@type/common';
import { downloadCsvFile } from 'utils/downloadFile';
import { fToDate } from 'utils/formatTime';
import {
  getIMUserParams,
  getIMUserResponse,
  getChannelsParams,
  getChannelsResponse,
  createChannelParams,
  createChannelResponse,
  getChannelTypeParams,
  getChannelTypeResponse,
  syncStaffChannelsResponse,
  syncTeachersChannelsResponse,
  getTicketsParams,
  getTicketsResponse,
  createTicketsPayload,
  createTicketsResponse,
  getQuestionTypesParams,
  getQuestionTypesResponse,
  tickets,
  rushTicketPayload,
  editTicketNamePayload,
  completeTicketPayload,
  transferTicketPayload,
  syncCustomersChannelsResponse,
  assignTicketPayload,
  toggleChannelPayload,
  toggleChannelResponse,
  getChannelDataResponse,
  getTicketsLogParams,
  getTicketsLogResponse,
  getCannedMessageTypeResponse,
  getCannedMessageResponse,
} from '../@type/sendbird';

export default (axios: AxiosInstance, sendbird: string) => ({
  /**
   * 取得user token跟資料
   */
  getIMUser(payload: getIMUserParams): TSBApiResponse<getIMUserResponse> {
    return axios.get(`${sendbird}/users/${payload.oneClubId}`);
  },
  /**
   * 建立老師sendbird帳號
   */
  createSendbirdUser(
    payload: getIMUserParams,
  ): TSBApiResponse<getIMUserResponse> {
    return axios.post(`${sendbird}/users`, payload);
  },
  /**
   * 查詢有無建立過頻道
   */
  getChannels(payload: getChannelsParams): TSBApiResponse<getChannelsResponse> {
    return axios.get(`${sendbird}/channels`, {
      params: payload,
    });
  },
  /**
   * 建立頻道
   */
  createChannel(
    payload: createChannelParams,
  ): TSBApiResponse<createChannelResponse> {
    return axios.post(`${sendbird}/channels`, payload);
  },
  /**
   * 取得聊天頻道類型
   */
  getChannelType(
    payload: getChannelTypeParams,
  ): TSBApiResponse<getChannelTypeResponse> {
    return axios.get(`${sendbird}/channel-types`, {
      params: payload,
    });
  },
  /**
   * 同步內勤人員的聊天頻道
   */
  syncStaffChannels(
    oneClubId: string,
  ): TSBApiResponse<syncStaffChannelsResponse> {
    return axios.post(`${sendbird}/channels/staffs/${oneClubId}/sync`);
  },
  /**
   * 同步老師的聊天頻道
   */
  syncTeachersChannels(
    oneClubId: string,
  ): TSBApiResponse<syncTeachersChannelsResponse> {
    return axios.post(`${sendbird}/channels/teachers/${oneClubId}/sync`);
  },
  /**
   * 取得任務單
   */
  getTickets(params: getTicketsParams): TSBApiResponse<getTicketsResponse> {
    return axios.get(`${sendbird}/tickets`, { params });
  },
  /**
   * 取得單筆任務單
   */
  getTicketsByChannelUrl(
    ticketId: string,
    role: TICKET_ROLE,
  ): TSBApiResponse<tickets> {
    return axios.get(`${sendbird}/tickets/${role}/${ticketId}`);
  },
  /**
   * 建立任務單
   */
  createTickets(
    payload: createTicketsPayload,
  ): TSBApiResponse<createTicketsResponse> {
    return axios.post(`${sendbird}/tickets`, payload);
  },
  /**
   * 加內勤人員進入頻道
   */
  joinStaffToChannel(id: string): TSBApiResponse<any> {
    return axios.patch(`${sendbird}/channels/${id}/staff/join`);
  },
  /**
   * 取得問題類別
   */
  getQuestionTypes(
    params: getQuestionTypesParams,
  ): TSBApiResponse<getQuestionTypesResponse> {
    return axios.get(`${sendbird}/question-types`, { params });
  },
  /**
   * 搶單
   */
  rushTicket(
    id: string,
    role: TICKET_ROLE,
    payload: rushTicketPayload,
  ): TSBApiResponse<tickets> {
    return axios.patch(`${sendbird}/tickets/${role}/${id}/rush`, payload);
  },
  /**
   * 編輯案件名稱
   */
  editTicketName(
    payload: editTicketNamePayload,
    role: TICKET_ROLE,
    id: string,
  ): TSBApiResponse<tickets> {
    return axios.patch(`${sendbird}/tickets/${role}/${id}/name`, payload);
  },
  /**
   * 完成案件
   */
  completeTicket(
    role: TICKET_ROLE,
    id: string,
    payload: completeTicketPayload,
  ): TSBApiResponse<tickets> {
    return axios.patch(`${sendbird}/tickets/${role}/${id}/status`, payload);
  },
  /**
   * 轉交案件
   */
  transferTicket(
    role: TICKET_ROLE,
    id: string,
    payload: transferTicketPayload,
  ): TSBApiResponse<tickets> {
    return axios.patch(`${sendbird}/tickets/${role}/${id}/transfer`, payload);
  },
  /**
   * 同步會員聊天室的頻道
   */
  syncCustomersChannels(
    oneClubId: string,
  ): TSBApiResponse<syncCustomersChannelsResponse> {
    return axios.post(`${sendbird}/channels/customers/${oneClubId}/sync`);
  },
  /**
   * 指派案件給某人
   */
  assignTicket(
    role: TICKET_ROLE,
    id: string,
    payload: assignTicketPayload,
  ): TSBApiResponse<tickets> {
    return axios.patch(`${sendbird}/tickets/${role}/${id}/assign`, payload);
  },
  /**
   * 開啟或關閉聊天室
   */
  toggleChannel(
    id: string,
    payload: toggleChannelPayload,
  ): TSBApiResponse<toggleChannelResponse> {
    return axios.patch(`${sendbird}/channels/${id}`, payload);
  },
  /**
   * 取得聊天頻道
   */
  getChannelData(id: string): TSBApiResponse<getChannelDataResponse> {
    return axios.get(`${sendbird}/channels/${id}`);
  },
  /**
   * 離開聊天頻道
   */
  leaveChannel(id: string): TSBApiResponse<getChannelDataResponse> {
    return axios.patch(`${sendbird}/channels/${id}/leave`);
  },
  /**
   * 取得單一案件歷程
   */
  getTicketsLog(
    role: TICKET_ROLE,
    id: string,
    params: getTicketsLogParams,
  ): TSBApiResponse<getTicketsLogResponse> {
    return axios.get(`${sendbird}/tickets/${role}/${id}/logs`, {
      params,
    });
  },
  /**
   * 匯出KPI
   */
  async exportKPI(
    params: {
      startAt: string;
      endAt: string;
    },
    role: TICKET_ROLE,
  ): Promise<any> {
    const excel: Blob = await axios.get(
      `${sendbird}/tickets/${role}/performances`,
      {
        params,
        responseType: 'blob',
      },
    );

    const typeCount = {
      type: 'application/csv',
    };
    const blob = new Blob([excel as unknown as BlobPart], typeCount);
    const fileName = `${fToDate(params.startAt)}~${fToDate(
      params.endAt,
    )}KPI報表`;

    if (excel.type !== typeCount.type) {
      const fileReader = new FileReader();
      fileReader.readAsText(blob, 'UTF-8');

      fileReader.onload = function () {
        const error = JSON.parse(fileReader.result as string).error;
        if (error) {
          return false;
        }
      };
    } else {
      downloadCsvFile(fileName, blob);
      return true;
    }
  },

  /**
   * 取得罐頭訊息分類
   */
  getCannedMessageTypes(params: {
    skip: number;
    limit: number;
  }): TSBApiResponse<getCannedMessageTypeResponse> {
    return axios.get(`${sendbird}/canned-message-types`, { params });
  },

  /**
   * 取得特定分類下的罐頭訊息
   */
  getCannedMessages(params: {
    skip: number;
    limit: number;
    type: string;
  }): TSBApiResponse<getCannedMessageResponse> {
    return axios.get(`${sendbird}/canned-messages`, { params });
  },
});
