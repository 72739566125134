import { createContext, useContext, useState, useEffect } from 'react';
import SendBird from '@sendbird/chat';
import {
  GroupChannelModule,
  GroupChannelHandler,
} from '@sendbird/chat/groupChannel';
import {
  OpenChannelModule,
  OpenChannelHandler,
} from '@sendbird/chat/openChannel';
import { Auth } from 'routes/path';
import { useGetNotifications } from 'hooks/query/notificationCenter';
import { NotificationItem } from 'api/notificationCenter/notification-center.type';
import SendbirdProvider from '@sendbird/uikit-react/SendbirdProvider';
import { useLoginService } from './LoginService';
import { useRemoteConfigService } from './RemoteConfigService';

const myColorSet = {
  '--sendbird-light-primary-500': '#091A7A',
  '--sendbird-light-primary-400': '#1939B7',
  '--sendbird-light-primary-300': '#3366FF',
  '--sendbird-light-primary-200': '#84A9FF',
  '--sendbird-light-primary-100': '#D6E4FF',
};

interface ISendbird {
  sendbirdAccessToken: string;
  customerChannelUnreadCount: number;
  ticketChannelUnreadCount: number;
  teacherChannelUnreadCount: number;
  sbInstance: any;
  myColorSet: typeof myColorSet;
  notificationList: NotificationItem[];
  notifyCount: number;
  isNotificationLoading: boolean;
  refetchNotificationList: () => void;
}

export const SendbirdInitContext = createContext({} as ISendbird);

const sbInstance = SendBird.init({
  appId: import.meta.env.VITE_SENDBIRD_APP_ID,
  modules: [new GroupChannelModule(), new OpenChannelModule()],
});

export const SendbirdInitProvider = ({ children }: { children: any }) => {
  const { loginUser } = useLoginService();
  const permissions = loginUser?.permissions || [];
  const includeAuth = (auth: string[]) =>
    auth.some((item: any) => permissions.includes(item));
  const { parameter } = useRemoteConfigService();
  const [customerChannelUnreadCount, setCustomerChannelUnreadCount] =
    useState(0);
  const [ticketChannelUnreadCount, setTicketChannelUnreadCount] = useState(0);
  const [teacherChannelUnreadCount, setTeacherChannelUnreadCount] = useState(0);
  const staffNumber = loginUser?.staffNumber || '';
  const isLogin = !!staffNumber;
  const channelTypesId = loginUser?.channelTypes?.map(item => item.id) || [];
  const hasTeacherTalkAuth = includeAuth([Auth.TEACHER_TALK]);
  const hasParentMessageEditAuth = includeAuth([Auth.PARENT_MESSAGE_EDIT]);

  // 拿到案件列表，只要五個
  const {
    data: notificationList,
    isLoading: isNotificationLoading,
    refetch: refetchNotificationList,
  } = useGetNotifications(
    {
      skip: 0,
      limit: 5,
    },
    isLogin && !!parameter.ONECLUB_7285,
  );

  // 案件、家長聊天室的未讀
  useEffect(() => {
    if (!sbInstance || !staffNumber || !loginUser?.IMAccessToken) return;

    // 教師聊天室頻道未讀
    async function getTeacherChannelHasUnreadMessage() {
      await sbInstance.connect(staffNumber || '', loginUser?.IMAccessToken);
      const count = await sbInstance.groupChannel.getTotalUnreadMessageCount({
        channelCustomTypesFilter: channelTypesId,
      });
      setTeacherChannelUnreadCount(count);
    }

    // 家長聊天室頻道未讀
    async function getCustomerChannelHasUnreadMessage() {
      await sbInstance.connect(staffNumber || '', loginUser?.IMAccessToken);
      const count = await sbInstance.groupChannel.getTotalUnreadMessageCount({
        channelCustomTypesFilter: [import.meta.env.VITE_IM_CUSTOMER_TYPE],
      });
      setCustomerChannelUnreadCount(count);
    }

    // 案件聊天室未讀
    async function getTicketChannelHasUnreadMessage() {
      await sbInstance.connect(staffNumber || '', loginUser?.IMAccessToken);
      const count = await sbInstance.groupChannel.getTotalUnreadMessageCount({
        channelCustomTypesFilter: [import.meta.env.VITE_PROGRESSING_TICKET_ID],
      });
      setTicketChannelUnreadCount(count);
    }

    if (hasTeacherTalkAuth && parameter.ONECLUB_5519) {
      getTeacherChannelHasUnreadMessage();
    }
    if (hasParentMessageEditAuth) {
      getCustomerChannelHasUnreadMessage();
    }
    getTicketChannelHasUnreadMessage();

    const groupChannelHandler = new GroupChannelHandler({
      onChannelChanged: channel => {
        if (channelTypesId.includes(channel.customType) && hasTeacherTalkAuth) {
          getTeacherChannelHasUnreadMessage();
        }
        if (
          channel.customType === import.meta.env.VITE_IM_CUSTOMER_TYPE &&
          hasParentMessageEditAuth
        ) {
          getCustomerChannelHasUnreadMessage();
        }
        if (channel.customType === import.meta.env.VITE_PROGRESSING_TICKET_ID) {
          getTicketChannelHasUnreadMessage();
        }
      },
    });

    sbInstance?.groupChannel.addGroupChannelHandler(
      import.meta.env.VITE_SENDBIRD_APP_ID,
      groupChannelHandler,
    );

    return () => {
      sbInstance?.groupChannel.removeGroupChannelHandler(
        import.meta.env.VITE_SENDBIRD_APP_ID,
      );
    };
  }, [sbInstance, staffNumber, loginUser?.IMAccessToken]);

  // 案件中心的未讀
  useEffect(() => {
    if (!sbInstance || !staffNumber || !loginUser?.IMAccessToken) return;

    // 加入openChannel，檢查案件通知的receiver如果有自己名字就重抓案件中心的未讀
    async function joinNotificationChannel() {
      await sbInstance.connect(staffNumber || '', loginUser?.IMAccessToken);
      const channel = await sbInstance.openChannel.getChannel(
        import.meta.env.VITE_IM_NOTIFICATION_OPEN_CHANNEL_URL,
      );

      await channel.enter();
    }

    joinNotificationChannel();

    const openChannelHandler = new OpenChannelHandler({
      onMessageReceived: (channel, message) => {
        const receiver = JSON.parse(message.data);

        const isReceiver = receiver?.receivers?.includes(staffNumber);
        if (isReceiver) {
          refetchNotificationList();
        }
      },
    });

    sbInstance?.openChannel.addOpenChannelHandler(
      import.meta.env.VITE_IM_NOTIFICATION_OPEN_CHANNEL_URL,
      openChannelHandler,
    );

    return () => {
      sbInstance?.openChannel.removeOpenChannelHandler(
        import.meta.env.VITE_IM_NOTIFICATION_OPEN_CHANNEL_URL,
      );
    };
  }, [sbInstance, staffNumber, loginUser?.IMAccessToken]);

  const value = {
    sendbirdAccessToken: loginUser?.IMAccessToken || '',
    customerChannelUnreadCount,
    ticketChannelUnreadCount,
    teacherChannelUnreadCount,
    sbInstance,
    myColorSet,
    notificationList: notificationList?.notifications || [],
    notifyCount: notificationList?.unreadCount || 0,
    isNotificationLoading,
    refetchNotificationList,
  };

  return (
    <SendbirdProvider
      appId={import.meta.env.VITE_SENDBIRD_APP_ID}
      userId={loginUser?.staffNumber || ''}
      colorSet={myColorSet}
      accessToken={loginUser?.IMAccessToken || ''}
    >
      <SendbirdInitContext.Provider value={value}>
        {children}
      </SendbirdInitContext.Provider>
    </SendbirdProvider>
  );
};

export const useSendbirdService = () => useContext(SendbirdInitContext);
