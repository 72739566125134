import { electroniccommerceAuth } from 'constants/auth/auth';
import { lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { checkPermissionsInAuth } from 'utils/helpr/permission';
import path, {
  Auth,
  FirstRoute,
  Route,
  SecondRoute,
  ThirdRoute,
  electroniccommerceCommodity,
  electroniccommerceOrder,
  electroniccommerceOrderListRead,
  electroniccommerceSetting,
} from '../path';
import { Loadable } from '../utils';

const ElectronicCommerce = Loadable(
  lazy(() => import('pages/electroniccommerce/ElectronicCommerce')),
);
const CommodityManage = Loadable(
  lazy(() => import('pages/electroniccommerce/commodity/Commodity')),
);
const Spu = Loadable(
  lazy(() => import('pages/electroniccommerce/commodity/spu/Spu')),
);
const SpuForm = Loadable(
  lazy(() => import('pages/electroniccommerce/commodity/spu/SpuForm')),
);
const Sku = Loadable(
  lazy(() => import('pages/electroniccommerce/commodity/sku/Sku')),
);
const SkuForm = Loadable(
  lazy(() => import('pages/electroniccommerce/commodity/sku/SkuForm')),
);
const Promotion = Loadable(
  lazy(() => import('pages/electroniccommerce/commodity/promotion/Promotion')),
);
const PromotionForm = Loadable(
  lazy(
    () => import('pages/electroniccommerce/commodity/promotion/PromotionForm'),
  ),
);
const Plan = Loadable(
  lazy(() => import('pages/electroniccommerce/commodity/plan/Plan')),
);
const PlanForm = Loadable(
  lazy(() => import('pages/electroniccommerce/commodity/plan/PlanForm')),
);
const Attributes = Loadable(
  lazy(
    () =>
      import('pages/electroniccommerce/commodity/sku/attributes/Attributes'),
  ),
);
const Setting = Loadable(
  lazy(() => import('pages/electroniccommerce/setting/Setting')),
);
const Mail = Loadable(
  lazy(() => import('pages/electroniccommerce/setting/mail/Mail')),
);
const Contract = Loadable(
  lazy(() => import('pages/electroniccommerce/setting/contract/Contract')),
);
const ExtensionContract = Loadable(
  lazy(
    () => import('pages/electroniccommerce/setting/extensionContract/Contract'),
  ),
);
const ExtensionContractForm = Loadable(
  lazy(
    () =>
      import('pages/electroniccommerce/setting/extensionContract/ContractForm'),
  ),
);
const LearningBarContract = Loadable(
  lazy(
    () =>
      import('pages/electroniccommerce/setting/learningBarContract/Contract'),
  ),
);
const LearningBarContractForm = Loadable(
  lazy(
    () =>
      import(
        'pages/electroniccommerce/setting/learningBarContract/ContractForm'
      ),
  ),
);
const Tos = Loadable(
  lazy(() => import('pages/electroniccommerce/setting/tos/Tos')),
);
const TosForm = Loadable(
  lazy(() => import('pages/electroniccommerce/setting/tos/TosForm')),
);
const ContractForm = Loadable(
  lazy(() => import('pages/electroniccommerce/setting/contract/ContractForm')),
);
const PaymentFlow = Loadable(
  lazy(
    () => import('pages/electroniccommerce/setting/paymentFlow/PaymentFlow'),
  ),
);
const Order = Loadable(
  lazy(() => import('pages/electroniccommerce/order/Order')),
);
const OrderList = Loadable(
  lazy(() => import('pages/electroniccommerce/order/OrderList')),
);
const OrderForm = Loadable(
  lazy(() => import('pages/electroniccommerce/order/OrderForm')),
);
const OrderFormTutorialCreate = Loadable(
  lazy(() =>
    import(
      'pages/electroniccommerce/order/ordertutorial/createorder/CreateOrder'
    ).then(({ CreateOrder }) => ({ default: CreateOrder })),
  ),
);
const OrderFormTutorialEdit = Loadable(
  lazy(() =>
    import(
      'pages/electroniccommerce/order/ordertutorial/editorder/EditOrder'
    ).then(({ EditOrder }) => ({ default: EditOrder })),
  ),
);
const OrderFormSchoolCreate = Loadable(
  lazy(() =>
    import(
      'pages/electroniccommerce/order/orderschool/createorder/CreateOrder'
    ).then(({ CreateOrder }) => ({ default: CreateOrder })),
  ),
);
const OrderFormSchoolEdit = Loadable(
  lazy(() =>
    import(
      'pages/electroniccommerce/order/orderschool/editorder/EditOrder'
    ).then(({ EditOrder }) => ({ default: EditOrder })),
  ),
);
const OrderFormLearningBarCreate = Loadable(
  lazy(() =>
    import(
      'pages/electroniccommerce/order/orderlearningbar/createorder/CreateOrder'
    ).then(({ CreateOrder }) => ({ default: CreateOrder })),
  ),
);
const OrderFormLearningBarEdit = Loadable(
  lazy(() =>
    import(
      'pages/electroniccommerce/order/orderlearningbar/editorder/EditOrder'
    ).then(({ EditOrder }) => ({ default: EditOrder })),
  ),
);

const OrderFormExtendedCreate = Loadable(
  lazy(() =>
    import(
      'pages/electroniccommerce/order/extended/createorder/CreateOrder'
    ).then(({ CreateOrder }) => ({ default: CreateOrder })),
  ),
);

const OrderFormExtendedEdit = Loadable(
  lazy(() =>
    import('pages/electroniccommerce/order/extended/editorder/EditOrder').then(
      ({ EditOrder }) => ({ default: EditOrder }),
    ),
  ),
);

const NowOrderFormEdit = Loadable(
  lazy(() => import('pages/electroniccommerce/order/now-order/now-order-form')),
);

const RefundAssistance = Loadable(
  lazy(
    () => import('pages/electroniccommerce/refundassistance/RefundAssistance'),
  ),
);
const RefundAssistanceForm = Loadable(
  lazy(
    () =>
      import('pages/electroniccommerce/refundassistance/RefundAssistanceForm'),
  ),
);

const generateElectronicCommerceRoutes = (permissions: Auth[]) => {
  const includeAuth = checkPermissionsInAuth(permissions);

  if (!includeAuth(electroniccommerceAuth)) {
    return null;
  }

  return {
    path: Route.electroniccommerce,
    element: <ElectronicCommerce />,
    children: (() => {
      const firstFolder: RouteObject[] = [
        {
          element: (
            <Navigate
              to={(() => {
                if (includeAuth(electroniccommerceCommodity)) {
                  return path.ELECTRONICCOMMERCE_COMMODITY;
                }
                if (includeAuth(electroniccommerceSetting)) {
                  return path.ELECTRONICCOMMERCE_SETTING;
                }
                if (includeAuth(electroniccommerceOrder)) {
                  return path.ELECTRONICCOMMERCE_ORDER;
                }
                return path.PERMISSION_ABNORMAL;
              })()}
            />
          ),
          index: true,
        },
      ];

      includeAuth(electroniccommerceCommodity) &&
        firstFolder.push({
          path: FirstRoute.commodity,
          element: <CommodityManage />,
          children: [
            {
              element: <Navigate to={path.ELECTRONICCOMMERCE_COMMODITY_SPU} />,
              index: true,
            },
            {
              path: SecondRoute.spu,
              children: [
                {
                  element: <Spu />,
                  index: true,
                },
                {
                  path: ThirdRoute.create,
                  element: <SpuForm />,
                },
                {
                  path: ThirdRoute.edit,
                  children: [
                    {
                      element: <Navigate to={Route.notFound} />,
                      index: true,
                    },
                    {
                      path: Route.id,
                      element: <SpuForm />,
                    },
                  ],
                },
              ],
            },
            {
              path: SecondRoute.sku,
              children: [
                {
                  element: <Sku />,
                  index: true,
                },
                {
                  path: ThirdRoute.create,
                  element: <SkuForm />,
                },
                {
                  path: ThirdRoute.edit,
                  children: [
                    {
                      element: <Navigate to={Route.notFound} />,
                      index: true,
                    },
                    {
                      path: Route.id,
                      element: <SkuForm />,
                    },
                  ],
                },
                {
                  path: ThirdRoute.attribute,
                  element: <Attributes />,
                },
              ],
            },
            {
              path: SecondRoute.plan,
              children: [
                {
                  element: <Plan />,
                  index: true,
                },
                {
                  path: ThirdRoute.create,
                  element: <PlanForm />,
                },
                {
                  path: ThirdRoute.edit,
                  children: [
                    {
                      element: <Navigate to={Route.notFound} />,
                      index: true,
                    },
                    {
                      path: Route.id,
                      element: <PlanForm />,
                    },
                  ],
                },
              ],
            },
            {
              path: SecondRoute.promotion,
              children: [
                {
                  element: <Promotion />,
                  index: true,
                },
                {
                  path: ThirdRoute.create,
                  element: <PromotionForm />,
                },
                {
                  path: ThirdRoute.edit,
                  children: [
                    {
                      element: <Navigate to={Route.notFound} />,
                      index: true,
                    },
                    {
                      path: Route.id,
                      element: <PromotionForm />,
                    },
                  ],
                },
              ],
            },
          ],
        });

      includeAuth(electroniccommerceSetting) &&
        firstFolder.push({
          path: FirstRoute.setting,
          element: <Setting />,
          children: [
            {
              element: (
                <Navigate to={path.ELECTRONICCOMMERCE_SETTING_PAYMENT} />
              ),
              index: true,
            },
            {
              path: SecondRoute.payment,
              children: [
                {
                  element: <PaymentFlow />,
                  index: true,
                },
              ],
            },
            {
              path: SecondRoute.mail,
              children: [
                {
                  element: <Mail />,
                  index: true,
                },
              ],
            },
            {
              path: SecondRoute.contract,
              children: [
                {
                  element: <Contract />,
                  index: true,
                },
                {
                  path: ThirdRoute.edit,
                  element: <ContractForm />,
                },
              ],
            },
            {
              path: SecondRoute.extensioncontract,
              children: [
                {
                  element: <ExtensionContract />,
                  index: true,
                },
                {
                  path: ThirdRoute.edit,
                  element: <ExtensionContractForm />,
                },
              ],
            },
            {
              path: SecondRoute.learningbarcontract,
              children: [
                {
                  element: <LearningBarContract />,
                  index: true,
                },
                {
                  path: ThirdRoute.edit,
                  element: <LearningBarContractForm />,
                },
              ],
            },
            {
              path: SecondRoute.tos,
              children: [
                {
                  element: <Tos />,
                  index: true,
                },
                {
                  path: ThirdRoute.edit,
                  element: <TosForm />,
                },
              ],
            },
          ],
        });

      includeAuth(electroniccommerceOrderListRead) &&
        firstFolder.push({
          path: FirstRoute.order,
          element: <Order />,
          children: [
            {
              element: <OrderList />,
              index: true,
            },
            {
              path: SecondRoute.create,
              element: <OrderForm />,
              index: true,
            },
            {
              path: SecondRoute.edit,
              children: [
                {
                  element: <Navigate to={Route.notFound} />,
                  index: true,
                },
                {
                  path: Route.id,
                  element: <OrderForm />,
                },
              ],
            },
            {
              path: SecondRoute.copy,
              children: [
                {
                  element: <Navigate to={Route.notFound} />,
                  index: true,
                },
                {
                  path: Route.id,
                  element: <OrderForm />,
                },
              ],
            },
            {
              path: SecondRoute.ordertutorial,
              children: [
                {
                  path: ThirdRoute.create,
                  element: <OrderFormTutorialCreate />,
                  children: [
                    {
                      path: Route.id,
                      element: <OrderFormTutorialCreate />,
                    },
                  ],
                },
                {
                  path: ThirdRoute.edit,
                  children: [
                    {
                      path: Route.id,
                      element: <OrderFormTutorialEdit />,
                    },
                  ],
                },
              ],
            },
            {
              path: SecondRoute.orderschool,
              children: [
                {
                  path: ThirdRoute.create,
                  element: <OrderFormSchoolCreate />,
                  children: [
                    {
                      path: Route.id,
                      element: <OrderFormSchoolCreate />,
                    },
                  ],
                },
                {
                  path: ThirdRoute.edit,
                  children: [
                    {
                      path: Route.id,
                      element: <OrderFormSchoolEdit />,
                    },
                  ],
                },
              ],
            },
            {
              path: SecondRoute.orderlearningbar,
              children: [
                {
                  path: ThirdRoute.create,
                  element: <OrderFormLearningBarCreate />,
                  children: [
                    {
                      path: Route.id,
                      element: <OrderFormLearningBarCreate />,
                    },
                  ],
                },
              ],
            },
            {
              path: SecondRoute.orderlearningbar,
              children: [
                {
                  path: ThirdRoute.edit,
                  children: [
                    {
                      path: Route.id,
                      element: <OrderFormLearningBarEdit />,
                    },
                  ],
                },
              ],
            },
            {
              path: SecondRoute.orderextended,
              children: [
                {
                  path: ThirdRoute.create,
                  element: <OrderFormExtendedCreate />,
                  children: [
                    {
                      path: Route.id,
                      element: <OrderFormExtendedCreate />,
                    },
                  ],
                },
              ],
            },
            {
              path: SecondRoute.orderextended,
              children: [
                {
                  path: ThirdRoute.edit,
                  children: [
                    {
                      path: Route.id,
                      element: <OrderFormExtendedEdit />,
                    },
                  ],
                },
              ],
            },
            {
              path: SecondRoute.noworder,
              children: [
                {
                  path: ThirdRoute.edit,
                  children: [
                    {
                      path: Route.id,
                      element: <NowOrderFormEdit />,
                    },
                  ],
                },
              ],
            },
          ],
        });

      firstFolder.push({
        path: FirstRoute.refundAssistance,
        element: <RefundAssistance />,
        children: [
          {
            element: <RefundAssistanceForm />,
            index: true,
          },
        ],
      });

      return firstFolder;
    })(),
  };
};

export { generateElectronicCommerceRoutes };
