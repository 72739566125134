import { ReactNode } from 'react';
import { SvgIconStyle, Mui } from '@onedesign/ui';
import { Breakpoint, styled, useTheme } from '@mui/material/styles';
import closeIcon from '/static/svg/ic_close.svg';
import { LoadingButton } from '@mui/lab';

const {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} = Mui;

const DialogStyles = styled(Dialog, {
  shouldForwardProp: props => props !== 'noPadding',
})(({ theme, noPadding }: { theme?: any; noPadding: boolean }) => ({
  '& .MuiDialogContent-root': {
    padding: noPadding ? 0 : theme.spacing(2),
  },
}));

const DialogActionsStyles = styled(DialogActions)(({ theme }) => ({
  padding: '13px !important',
  borderTop: `1px solid ${theme.palette.grey[500_24]} !important`,
}));

export type TButton = {
  isHidden?: boolean;
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning';
  text?: string;
  disabled?: boolean;
  action: () => void;
  isLoading?: boolean;
};
interface IDialogs {
  open: boolean;
  title: string | ReactNode;
  operateNode?: ReactNode;
  children?: ReactNode;
  customFooter?: ReactNode;
  close: TButton;
  disConfirm?: TButton;
  confirm?: TButton;
  width?: string;
  maxWidth?: Breakpoint;
  fullWidth?: boolean;
  noPadding?: boolean;
  disableBackdrop?: boolean;
}

export const Dialogs = ({
  open,
  title,
  operateNode,
  children,
  close,
  disConfirm,
  confirm,
  width,
  maxWidth,
  fullWidth,
  noPadding = false,
  disableBackdrop = false,
  customFooter,
}: IDialogs) => {
  const theme = useTheme();

  return (
    <DialogStyles
      open={open}
      onClose={!disableBackdrop ? close?.action : undefined}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      noPadding={noPadding}
    >
      <Box
        sx={{
          width: width || '600px',
          [theme.breakpoints.down('sm')]: {
            width: '100%',
          },
          maxHeight: '100vh',
        }}
      >
        <DialogTitle
          sx={{
            p: 1.5,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ px: 1.5 }}>{title}</Box>
          <Box sx={{ ml: 1.5 }}>
            {operateNode}
            {close && close.text && (
              <IconButton
                size="small"
                aria-label="close"
                onClick={close.action}
                disabled={close.disabled || false}
                sx={{
                  color: theme => theme.palette.grey[500],
                }}
              >
                <SvgIconStyle src={closeIcon} />
              </IconButton>
            )}
          </Box>
        </DialogTitle>
        {children && (
          <DialogContent
            dividers
            sx={{
              height: 'calc(100% - 72px)',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {children}
          </DialogContent>
        )}
        {!customFooter && (disConfirm || confirm) && (
          <DialogActionsStyles>
            {disConfirm && !disConfirm?.isHidden && (
              <Button
                variant="outlined"
                color={disConfirm.color}
                onClick={disConfirm.action}
                disabled={disConfirm.disabled || false}
              >
                {disConfirm.text}
              </Button>
            )}
            {confirm && !confirm?.isHidden && (
              <LoadingButton
                variant="contained"
                color={confirm.color}
                onClick={confirm.action}
                disabled={confirm.disabled || false}
                loading={confirm.isLoading || false}
              >
                {confirm.text}
              </LoadingButton>
            )}
          </DialogActionsStyles>
        )}
        {customFooter && customFooter}
      </Box>
    </DialogStyles>
  );
};
