import { shipManagementAuth } from 'constants/auth/auth';
import { lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { checkPermissionsInAuth } from 'utils/helpr/permission';
import path, {
  Auth,
  FirstRoute,
  Route,
  SecondRoute,
  badgeEdit,
  badgeRead,
  textbookCreate,
  textbookEdit,
  textbookRead,
  textbookReadInfo,
} from '../path';
import { Loadable } from '../utils';

const BadgeCentral = Loadable(
  lazy(() => import('pages/badgecentral/BadgeCentral')),
);

const RewardShipment = Loadable(
  lazy(
    () =>
      import(
        'pages/badgecentral/rewardshipmentmanagement/RewardShipmentManagement'
      ),
  ),
);
const RewardShipmentForm = Loadable(
  lazy(
    () =>
      import(
        'pages/badgecentral/rewardshipmentmanagement/RewardShipmentForm/RewardShipmentForm'
      ),
  ),
);
const Textbook = Loadable(
  lazy(() => import('pages/badgecentral/textbook/Textbook')),
);
const TextBookForm = Loadable(
  lazy(() => import('pages/badgecentral/textbook/textbookForm/TextbookForm')),
);

const generateShipManagementRoutes = (permissions: Auth[]) => {
  const includeAuth = checkPermissionsInAuth(permissions);

  if (!includeAuth(shipManagementAuth)) {
    return null;
  }

  return {
    path: Route.badgecentral,
    element: <BadgeCentral />,
    children: (() => {
      const firstFolder: RouteObject[] = [
        {
          element: (
            <Navigate
              to={(() => {
                if (includeAuth(badgeRead)) {
                  return path.MEDAL_CENTRAL_REWARD_REDEMPTION;
                }
                if (includeAuth(textbookRead)) {
                  return path.TEXTBOOK_MANAGEMENT;
                }
                return path.PERMISSION_ABNORMAL;
              })()}
            />
          ),
          index: true,
        },
      ];

      includeAuth(badgeEdit) &&
        firstFolder.push({
          path: FirstRoute.rewardshipmentmanagement,
          element: <RewardShipment />,
          children: [
            {
              path: SecondRoute.edit,
              children: [
                {
                  element: <Navigate to={Route.notFound} />,
                  index: true,
                },
                {
                  path: Route.id,
                  element: <RewardShipmentForm />,
                },
              ],
            },
          ],
        });

      includeAuth(textbookRead) &&
        firstFolder.push({
          path: FirstRoute.textbookmangement,
          element: <Textbook />,
          children: (() => {
            const secondFolder: RouteObject[] = [
              {
                element: (
                  <Navigate
                    to={(() => {
                      if (includeAuth(textbookCreate)) {
                        return path.TEXTBOOK_CREATE;
                      }
                      if (includeAuth(textbookReadInfo)) {
                        return path.TEXTBOOK_READ;
                      }
                      if (includeAuth(textbookEdit)) {
                        return path.TEXTBOOK_EDIT;
                      }
                      return path.PERMISSION_ABNORMAL;
                    })()}
                  />
                ),
                index: true,
              },
            ];

            includeAuth(textbookCreate) &&
              secondFolder.push({
                path: SecondRoute.create,
                element: <TextBookForm />,
              });

            includeAuth(textbookReadInfo) &&
              secondFolder.push({
                path: SecondRoute.read,
                children: [
                  {
                    element: <Navigate to={path.NOT_FOUND} />,
                    index: true,
                  },
                  {
                    path: Route.id,
                    element: <TextBookForm />,
                  },
                ],
              });

            includeAuth(textbookEdit) &&
              secondFolder.push({
                path: SecondRoute.edit,
                children: [
                  {
                    element: <Navigate to={path.NOT_FOUND} />,
                    index: true,
                  },
                  {
                    path: Route.id,
                    element: <TextBookForm />,
                  },
                ],
              });
            return secondFolder;
          })(),
        });

      return firstFolder;
    })(),
  };
};

export { generateShipManagementRoutes };
