export enum ERemoteConfigParameter {
  MMS_TEACHER_SIGNING = 'MMS_TEACHER_SIGNING',
  ONECLUB_5519 = 'ONECLUB_5519',
  ONECLUB_5214 = 'ONECLUB_5214',
  ONECLUB_5917 = 'ONECLUB_5917',
  ONECLUB_6237 = 'ONECLUB_6237',
  ONECLUB_6599 = 'ONECLUB_6599',
  ONECLUB_7167 = 'ONECLUB_7167',
  ONECLUB_7111 = 'ONECLUB_7111',
  ONECLUB_7285 = 'ONECLUB_7285',
  ONE_ANSWER = 'ONE_ANSWER',
  NEW_BATCH_SCHEDULED_COURSE = 'NEW_BATCH_SCHEDULED_COURSE',
  ONECLASS_NOW = 'ONECLASS_NOW',
  NOW_ORDER = 'NOW_ORDER',
  ONECLUB_9182 = 'ONECLUB_9182',
}
