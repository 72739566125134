import { AxiosInstance } from 'axios';
import { TApiResponse } from 'src/@type/common';

export default (axios: AxiosInstance, apiPrefix: string) => ({
  /**
   * @param orderId 訂單id
   * @description 寄送合約即將逾期的通知信＆簡訊
   */
  sendOverdueNotifications(orderId: string): TApiResponse<boolean> {
    return axios.post(`${apiPrefix}/orders/overdue/notifications/${orderId}`);
  },
});
