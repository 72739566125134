import backstageApi from 'api/backstage';
import resourceLibraryApi from 'api/resourceLibrary';
import teacherApi from 'api/teacher';
import electronicCommerceApi from 'api/electronicCommerce';
import courseApi from 'api/course';
import oneClubSNSApi from 'api/oneClubSNS';
import oneClubUsers from 'api/oneClubUsers';
import bitrixApi from 'api/bitrix';
import bitrixHook from 'api/bitrixHook';
import liveClassApi from 'api/liveClass';
import teachersBackendApi from 'api/teacherManagement';
import oneClassApiGateway from 'api/oneClassApiGateway';
import salaryApi from 'api/salary';
import bonusApi from 'api/bonus';
import tagApi from 'api/tag';
import oneboardApi from 'api/oneboard';
import leaveApi from 'api/leave';
import oneExamApi from 'api/oneExam';
import googleSheet from 'api/googleSheet';
import performanceApi from 'api/perfomance';
import teacherRecruitment from 'api/teacherRecruitment';
import schoolApi from 'api/school';
import sendbirdApi from 'api/sendbird';
import funLearningApi from 'api/funLearning';
import oneAnswerApi from 'api/oneAnswer/oneAnswer';
import mmsApi from 'api/mms';
import productApi from 'api/product';
import oneClassNow from 'api/oneClassNow';
import notificationCenter from 'api/notificationCenter';
import instance from './instance';

export const URL = {
  BACKSTAGE: import.meta.env.VITE_BACKSTAGE_API_DOMAIN,
  ONECLUB: import.meta.env.VITE_ONECLUB_API_DOMAIN,
  TEACHER: import.meta.env.VITE_TEACHER_API_DOMAIN,
  TEACHER_RECRUITMENT: import.meta.env.VITE_TEACHER_RECRUITMENT_API_DOMAIN,
  RESOURCE_LIBRARY: import.meta.env.VITE_RESOURCE_LIBRARY_API_DOMAIN,
  ELECTRONIC_COMMERCE: import.meta.env.VITE_ELECTRONIC_COMMERCE_API_DOMAIN,
  COURSE: import.meta.env.VITE_COURSE_API_DOMAIN,
  ONECLUB_SNS: import.meta.env.VITE_ONECLUB_SNS_DOMAIN,
  ONECLUB_USERS: import.meta.env.VITE_ONECLUB_USERS_DOMAIN,
  BITRIX: import.meta.env.VITE_BITRIX_PROD_API_DOMAIN,
  BITRIX_HOOK: import.meta.env.VITE_BITRIX_HOOK_DOMAIN,
  LIVE_CLASS: import.meta.env.VITE_LIVE_CLASS_API_DOMAIN,
  ORDER: import.meta.env.VITE_ORDER_RESULT_DOMAIN, // TODO,
  TEACHERS_MANAGEMENT: import.meta.env.VITE_TEACHERS_BACKEND_API_DOMAIN,
  ONECLASS_API_GATEWAY: import.meta.env.VITE_ONECLASS_API_GATEWAY_DOMAIN,
  ONEBOARD: import.meta.env.VITE_ONEBOARD_API_URL,
  ONEEXAM_API: import.meta.env.VITE_ONEEXAM_API_DOMAIN,
  GOOGLE_SHEET: import.meta.env.VITE_GOOGLE_SHEET_DOMAIN,
  EDU_SUBJECT_VN: import.meta.env.VITE_EDU_SUBJECT_VN,
  SCHOOL_API: import.meta.env.VITE_SCHOOL_API_DOMAIN,
  SENDBIRD_API: import.meta.env.VITE_SENDBIRD_API_DOMAIN,
  FUN_LEARNING: import.meta.env.VITE_FUN_LEARNING_API_DOMAIN,
  ONE_ANSWER: import.meta.env.VITE_ONE_ANSWER_API_DOMAIN,
  NOTIFICATION_CENTER: import.meta.env.VITE_NOTIFICATION_CENTER_API_DOMAIN,
};

export default {
  rootApiteacherRecruitment: teacherRecruitment(
    instance,
    `${URL.TEACHER_RECRUITMENT}`,
  ),
  backstage: backstageApi(instance, `${URL.BACKSTAGE}`),
  resourceLibrary: resourceLibraryApi(instance, `${URL.RESOURCE_LIBRARY}/api`),
  teacher: teacherApi(instance, `${URL.TEACHER}/teachers`),
  teacherRecruitment: teacherRecruitment(
    instance,
    `${URL.TEACHER_RECRUITMENT}/teacher-recruitment`,
  ),
  electronicCommerce: electronicCommerceApi(
    instance,
    `${URL.ELECTRONIC_COMMERCE}`,
  ),
  course: courseApi(instance, `${URL.COURSE}/api`),
  oneClubSNS: oneClubSNSApi(instance, `${URL.ONECLUB_SNS}`),
  oneClubUsers: oneClubUsers(instance, `${URL.ONECLUB_USERS}`),
  bitrix: bitrixApi(instance, `${URL.BITRIX}`),
  bitrixHook: bitrixHook(instance, `${URL.BITRIX_HOOK}`),
  liveClass: liveClassApi(instance, `${URL.LIVE_CLASS}`),
  teachersManagement: teachersBackendApi(
    instance,
    `${URL.TEACHERS_MANAGEMENT}/api`,
  ),
  oneClassApiGateway: oneClassApiGateway(
    instance,
    `${URL.ONECLASS_API_GATEWAY}`,
  ),
  salary: salaryApi(instance, `${URL.ONECLASS_API_GATEWAY}`),
  bonus: bonusApi(instance, `${URL.ONECLASS_API_GATEWAY}/backend/bonus`),
  tag: tagApi(instance, `${URL.ONECLASS_API_GATEWAY}`),
  oneboard: oneboardApi(instance, `${URL.ONEBOARD}`),
  leave: leaveApi(instance, `${URL.ONECLASS_API_GATEWAY}`),
  oneExam: oneExamApi(instance, `${URL.ONEEXAM_API}`),
  googleSheet: googleSheet(instance, `${URL.GOOGLE_SHEET}`),
  performance: performanceApi(instance, `${URL.ELECTRONIC_COMMERCE}`),
  eduSubjectVN: oneClassApiGateway(instance, `${URL.EDU_SUBJECT_VN}`),
  school: schoolApi(instance, `${URL.SCHOOL_API}`),
  sendbird: sendbirdApi(instance, `${URL.SENDBIRD_API}`),
  funLearning: funLearningApi(instance, `${URL.FUN_LEARNING}`),
  oneAnswer: oneAnswerApi(instance, `${URL.ONE_ANSWER}`),
  mms: mmsApi(instance, `${URL.ONECLASS_API_GATEWAY}`),
  product: productApi(instance, `${URL.ELECTRONIC_COMMERCE}`),
  oneClassNow: oneClassNow(instance, `${URL.ONECLASS_API_GATEWAY}`),
  notificationCenter: notificationCenter(
    instance,
    `${URL.NOTIFICATION_CENTER}`,
  ),
};
