import { useState, useMemo } from 'react';
import '@sendbird/uikit-react/dist/index.css';
import 'components/sendbird/sendbirdKit.css';
import { Mui } from '@onedesign/ui';
import { EChannelTargetType } from 'components/sendbird/sendbirdKit.constant';
import { useSendbirdService } from 'services/SendbirdService';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import { useLoginService } from 'services/LoginService';
import { ChannelListProvider } from '@sendbird/uikit-react/ChannelList/context';
import { sendbirdTab } from '@type/sendbird';
import ChannelListUI from './ChannelListUI';

const { Box } = Mui;

const SendbirdChannelNotify = () => {
  const [currentChannelSelected, setCurrentChannelSelected] =
    useState<GroupChannel>();
  const { loginUser } = useLoginService();
  const { sendbirdAccessToken } = useSendbirdService();
  const [value, setValue] = useState(EChannelTargetType.TEACHER);
  const channelTypes: sendbirdTab[] = loginUser?.channelTypes || [];
  const channelTypesId = useMemo(() => {
    return channelTypes?.map(item => item.id);
  }, [channelTypes]);

  if (!loginUser?.staffNumber || !sendbirdAccessToken) return null;

  return (
    <Box
      sx={{
        pl: '32px',
        pr: '32px',
        pb: '120px',
        pt: '32px',
      }}
    >
      <Box>
        <ChannelListProvider
          queries={{
            channelListQuery: {
              includeEmpty: true,
              customTypesFilter:
                value === EChannelTargetType.CUSTOMER
                  ? [import.meta.env.VITE_IM_CUSTOMER_TYPE]
                  : channelTypesId,
            },
          }}
          disableAutoSelect={true}
          isTypingIndicatorEnabled={true}
        >
          <ChannelListUI
            setCurrentChannelSelected={setCurrentChannelSelected}
            currentChannelSelected={currentChannelSelected}
            setValue={setValue}
            value={value}
          />
        </ChannelListProvider>
      </Box>
    </Box>
  );
};

export default SendbirdChannelNotify;
